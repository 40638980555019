<template>
  <CSidebar
    fixed
    :minimize="minimize"
    :show="show"
    @update:show="(value) => $store.commit('set', ['sidebarShow', value])"
  >
    <CSidebarBrand class="d-md-down-none" to="/">
      <div class="logo c-sidebar-brand-full">
        <img src="../../public/img/gms.png" />
      </div>
      <div class="logoS c-sidebar-brand-minimized">
        <img src="../../public/img/g.png" />
      </div>
    </CSidebarBrand>

    <CRenderFunction flat :content-to-render="nav" />
    <CSidebarMinimizer
      class="d-md-down-none"
      @click.native="$store.commit('set', ['sidebarMinimize', !minimize])"
    />
  </CSidebar>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import nav from './_nav'

export default {
  name: 'TheSidebar',
  nav,
  data() {
    return {}
  },
  mounted() {},
  computed: {
    ...mapGetters(['']),
    show() {
      return this.$store.state.sidebarShow
    },
    minimize() {
      return this.$store.state.sidebarMinimize
    },
    nav() {
      return [
        {
          _name: 'CSidebarNav',
          _children: [
            {
              _name: 'CSidebarNavTitle',
              _children: ['Menu'],
            },
            {
              _name: 'CSidebarNavItem',
              name: 'Dashboard',
              to: '/dashboard',
              icon: 'cil-speedometer',
            },
            {
              _name: 'CSidebarNavItem',
              name: '公司管理',
              to: '/companies',
              icon: 'cil-building',
            },
            {
              _name: 'CSidebarNavItem',
              name: '用戶管理',
              to: '/users',
              icon: 'cil-address-book',
            },
            {
              _name: 'CSidebarNavItem',
              name: '角色管理',
              to: '/role',
              icon: 'cil-cog',
            },

            {
              _name: 'CSidebarNavItem',
              name: '匯率管理',
              to: '/exchangeRate',
              icon: 'cil-money',
            },
            {
              _name: 'CSidebarNavItem',
              name: '收款帳號',
              to: '/bankAccount',
              icon: 'cil-spreadsheet',
            },
            {
              _name: 'CSidebarNavItem',
              name: '單據管理',
              to: '/statements',
              icon: 'cilNewspaper',
            },
            {
              _name: 'CSidebarNavItem',
              name: '帳單上傳',
              to: '/upload',
              icon: 'cil-cloud-upload',
            },
          ],
        },
      ]
    },
  },
  created() {},
}
</script>
