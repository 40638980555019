<template>
  <CDropdown
    inNav
    class="c-header-nav-items roleUser"
    placement="bottom-end"
    add-menu-classes="pt-0"
  >
    <template #toggler>
      <CHeaderNavLink>
        <!-- 登入者帳號 -->
        <CRow>
          <CCol lg="6" sm="6">
            <span class="userAcc"> 公司 - USERNAME </span>
          </CCol>
          <CCol lg="5" sm="5">
            <span class="userRole">角色:</span>
          </CCol>
          <CCol lg="1" sm="1" class="userPic">
            <div class="c-avatar imgUser">
              <img src="img/avatars/user.png" class="c-avatar-img " />
            </div>
          </CCol>
        </CRow>
      </CHeaderNavLink>
    </template>

    <CDropdownHeader tag="div" class="text-center" color="light">
      <strong>Settings</strong>
    </CDropdownHeader>
    <CDropdownItem @click="profile()">
      <CIcon name="cil-user" /> Profile
    </CDropdownItem>
    <CDropdownItem @click="logout()">
      <CIcon name="cil-lock-locked" /> Logout
    </CDropdownItem>
  </CDropdown>
</template>

<script>
export default {
  name: 'TheHeaderDropdownAccnt',
  data() {
    return {
      company: sessionStorage.getItem('companyName'),
      userName: sessionStorage.getItem('userName'),
      role: sessionStorage.getItem('role'),
      userAuth: sessionStorage.getItem('userAuth'),
    }
  },
  mounted() {},
  methods: {
    logout() {
      this.$store.dispatch('actionLogout')
    },
    profile() {
      this.$router.push('/Profile')
    },
  },
}
</script>

<style scoped>
.c-icon {
  margin-right: 0.3rem;
}
</style>
