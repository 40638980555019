<template>
  <div>
    <CHeader fixed with-subheader light>
      <CToggler
        in-header
        class="ml-3 d-lg-none"
        @click="$store.commit('toggleSidebarMobile')"
      />
      <CToggler
        in-header
        class="ml-3 d-md-down-none"
        @click="$store.commit('toggleSidebarDesktop')"
      />
      <CHeaderBrand class="mx-auto d-lg-none"> </CHeaderBrand>
      <CHeaderNav class="d-md-down-none mr-auto"> </CHeaderNav>
      <ul class="cHeaderNav mr-4 roleAll">
        <TheHeaderDropdownAccnt />
      </ul>

      <CSubheader class="px-3">
        <CBreadcrumbRouter class="border-0 mb-0" />
      </CSubheader>
    </CHeader>
  </div>
</template>

<script>
import TheHeaderDropdownAccnt from './TheHeaderDropdownAccnt'

export default {
  name: 'TheHeader',
  components: {
    TheHeaderDropdownAccnt,
  },
  data() {
    return {
      requestPop: false,
      askPop: false,
    }
  },
}
</script>
